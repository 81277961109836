import { Button, DropdonwFlags } from '@/components';
import { CustomTable } from '@/components/Table';
import SuperCollapse from '@/components/sharedComponents/SuperCollapse';
import { toCurrency } from '@/helpers/masks/toCurrency';
import EventMainPdvReport from '@/model/EventMainPdvReport';
import PaymentType from '@/model/PaymentType';
import React from 'react';

export interface SalePdvContentProps {
  generatePDF: () => void;
  salePdv: EventMainPdvReport[] | undefined;
  numberToPaymentType: (status: PaymentType) => string;
}

export const SalePdvContent: React.FC<SalePdvContentProps> = ({
  salePdv,
  generatePDF,
  numberToPaymentType,
}) => (
  <>
    <div className="d-flex justify-content-between">
      <h5>Vendas por PDV</h5>
      <Button theme="outlineDark" onClick={() => generatePDF()} title="Exportar relatório" />
    </div>
    {salePdv?.map((data: EventMainPdvReport, index: number) => (
      <React.Fragment key={`PDV-${index}-${data.pdv.name}`}>
        <div className="collapseTableText">
          <div className="d-flex mb-3">{data.pdv.name}</div>
        </div>
        <SuperCollapse
          width={'100%'}
          title={
            <CustomTable
              numberRowsPerPage={0}
              progressPending={false}
              theme="secondaryWithoutBorder"
              columns={[
                {
                  name: 'Vendidos',
                  selector: row => row.ticketsSold,
                  width: '15%',
                },
                {
                  name: 'Cortesias',
                  selector: row => row.courtesies,
                  width: '15%',
                },
                {
                  name: 'Cancelados',
                  selector: row => row.canceledTickets,
                  width: '15%',
                },
                {
                  name: 'Forma de pagamentos',
                  selector: row => row.paymentMethods,
                  width: '25%',
                },
                {
                  name: 'Descontos',
                  selector: row => row.discount,
                  width: '15%',
                },
                {
                  name: 'Total arrecadado',
                  selector: row => row.totalRaised,
                  width: '15%',
                },
              ]}
              data={
                data.summary.totalRaised
                  ? [
                      {
                        ticketsSold: data.summary.ticketsSold,
                        courtesies: data.summary.courtesies,
                        canceledTickets: data.summary.canceledTickets,
                        paymentMethods: (
                          <DropdonwFlags
                            pointerClass={true}
                            dataColumn={
                              data?.summary?.paymentMethods.map(payment => ({
                                id: String(payment?.payment),
                                name: `${numberToPaymentType(payment?.payment)} ${toCurrency(
                                  payment.value,
                                )}`,
                              })) || []
                            }
                          />
                        ),
                        discount: toCurrency(data.summary.discount),
                        totalRaised: toCurrency(data.summary.totalRaised),
                      },
                    ]
                  : []
              }
            />
          }
          content={
            <>
              {data.events &&
                data.events.length > 0 &&
                data.events.map(event => (
                  <>
                    <h6>{event.eventName}</h6>
                    <CustomTable
                      key={event.eventId}
                      numberRowsPerPage={0}
                      progressPending={false}
                      theme="secondaryWithoutBorder"
                      columns={[
                        {
                          name: 'Ingresso',
                          selector: row => row.name,
                          width: '40%',
                        },
                        {
                          name: 'Vendidos',
                          selector: row => row.ticketsSold,
                          width: '15%',
                        },
                        {
                          name: 'Cortesia',
                          selector: row => row.ticketsCourtesy,
                          width: '15%',
                        },
                        {
                          name: 'Cancelados',
                          selector: row => row.ticketsCancel,
                          width: '15%',
                        },
                        {
                          name: 'Valor',
                          selector: row => row.value,
                          width: '15%',
                        },
                      ]}
                      data={
                        event.tickets && event.tickets.length > 0
                          ? event.tickets.map(ticket => ({
                              name: `${ticket.ticket.name} - ${ticket.batch.name}`,
                              ticketsSold: ticket.ticketsSold || '---',
                              ticketsCourtesy: ticket.ticketsCourtesy || '---',
                              ticketsCancel: ticket.ticketsCancel || '---',
                              value: toCurrency(ticket.value) || '---',
                            }))
                          : []
                      }
                    />
                  </>
                ))}
            </>
          }
        />
      </React.Fragment>
    ))}
  </>
);
