import React from 'react';
import { ReactComponent as Success } from '@/assets/images/svg/success.svg';
import TicketPdf from '@/model/TicketPdf';
import { Button } from '@/components';

interface StateProps {
  tickets: TicketPdf[];
  orderId: string;
}

interface DispatchProps {
  onSendToServerPrinter: (orderId: string) => void;
}

type Props = StateProps & DispatchProps;

export const TicketContent: React.FC<Props> = ({ tickets, orderId, onSendToServerPrinter }) => {
  const showButton = (): boolean => {
    let show = false;
    if (tickets && tickets.length > 0) {
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < tickets.length; i++) {
        if (tickets[i].serverPrinter) {
          show = true;
          break;
        }
      }
    }
    return show;
  };
  return (
    <div className="filter-modal-content">
      <div className="exclude-container">
        <Success color="#E64F49" />
        <div className="header-title-text modal__title">
          <h5 className="modal__confirmation-title">Seu Pedido foi realizado com sucesso</h5>
        </div>
        <div className="modal__confirmation-text" style={{ marginBottom: '20px' }}>
          Para baixar seu(s) ingresso(s), click sobre o <strong>ingresso</strong>.
        </div>
        {showButton() && (
          <div className="modal__confirmation-text" style={{ marginBottom: '20px' }}>
            <Button title="Enviar para impressora" onClick={() => onSendToServerPrinter(orderId)} />
          </div>
        )}

        {tickets.map((data, index) => (
          <div key={`${index}-${data.name}`} className="modal__confirmation-text">
            {data.serverPrinter ? (
              <label>{data.name}</label>
            ) : (
              <a href="#" onClick={() => window.open(data.url, '_blank')}>
                {data.name}
              </a>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};
